@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f6fd;
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000;
}

h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #000;
}

h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #000;
}

h5 {
  font-size: 0.875rem;
  font-weight: 600;
  color: #000;
}

h6 {
  font-size: 0.75rem;
  font-weight: 600;
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-top-right-radius: 100vh;
  border-bottom-right-radius: 100vh;
}

::-webkit-scrollbar-thumb {
  background: #969494;
  border-radius: 100vh;
}

::-webkit-scrollbar-thumb:hover {
  background: #576a8f;
}

.btn {
  @apply rounded-lg font-semibold capitalize transition-all flex items-center justify-center gap-2;
}

.btn-sm {
  @apply px-4 h-10 text-xs;
}
.btn-md {
  @apply px-4 h-12 text-sm;
}
.btn-lg {
  @apply px-5 h-14 text-sm;
}

.btn-primary {
  @apply bg-primary text-white;
}

.btn-danger {
  @apply bg-danger border border-solid border-danger hover:bg-[#f31225] text-white;
}

.btn-warning {
  @apply bg-warning border border-solid border-warning hover:bg-[#edb23b] text-dark;
}

.btn-success {
  @apply bg-success border border-solid border-success hover:bg-success/80 text-white;
}

.btn-default {
  @apply bg-[#EFEFEF] text-dark border border-solid border-[#EFEFEF] hover:bg-[#EFEFEF];
}

.btn-disabled {
  opacity: 0.6;
}

.btn-outlined {
  @apply !bg-transparent text-slate-600;
}

.active_menu {
  @apply !bg-primary !text-white;
}

.card {
  @apply p-4 md:p-6 bg-white rounded-xl;
}

/* badge color */
.badge-success {
  @apply !text-success;
}
.badge-danger {
  @apply !text-danger;
}
.badge-info {
  @apply !text-info;
}
.badge-warning {
  @apply !text-warning;
}

/* table css */
table {
  width: 100%;
}

.sticky-table {
  max-height: 400px;
  overflow: auto;
}

.table-responsive {
  overflow: auto;
  max-width: calc(100vw - 70px);
}

.table-responsive table {
  min-width: 900px;
}

table thead {
  @apply sticky left-0 top-0 bg-white w-full;
}

table thead tr td {
  @apply text-gray text-base pb-2 px-3;
}

table tbody tr td {
  @apply text-dark text-sm font-semibold py-4 px-3;
}
table thead tr td:first-child,
table tbody tr td:first-child {
  @apply !pl-0;
}

.react-date-picker__inputGroup input:focus {
  outline: none;
  background-color: unset;
  border: unset;
  box-shadow: unset;
  user-select: none;
}

.react-calendar__tile--active {
  background: #006edc;
  color: #fff;
}

.react-date-picker__wrapper {
  border: none !important;
}
